<template>
    <div class="edu-frame">
        <edu-nav-bar title="选择课程" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <van-dropdown-menu>
            <van-dropdown-item title="搜索" ref="item" icon="search">
                <van-field v-model="tablePage.condition.forder" label="序号" placeholder="请输入序号" input-align="right" clearable/>
                <van-field v-model="tablePage.condition.className" label="课程名称" placeholder="请输入课程名称" input-align="right" clearable/>
                <van-cell center title="仅显示未满" style="font-size: 14px;color: #646566;">
                    <template #right-icon>
                        <van-checkbox style="margin-right: 13px;" v-model="tablePage.condition.showNotFull"></van-checkbox>
                    </template>
                </van-cell>
                <div style="padding: 5px 16px;">
                    <van-button type="info" block round @click="onSearch">搜索</van-button>
                </div>
            </van-dropdown-item>
        </van-dropdown-menu>
        <van-notice-bar v-if="!isRetire&&cust.loginType!=LOGINTYPE_CHILD&&$route.query.termTime!='shuqi'" left-icon="volume-o" text="*您还未达退休年龄，每个课程已加收50%学费（部分课程除外）。" style="color: red;"/>
        <van-list class="content-frame" :style="isRetire||cust.loginType==LOGINTYPE_CHILD ? 'height: calc(100vh - 46px - 44px - 48px)': 'height: calc(100vh - 46px - 44px - 48px - 40px)'" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getBaoduCourses">
            <div v-for="(clazz, index) in classes" :key="index" class="edu-group class-item"
                 @click="checkCourse(clazz)">
                <van-checkbox style="margin-right: 13px;" v-model="clazz.checked"></van-checkbox>
                <div class="class-detail">
                    <div class="class-detail-title">{{clazz.className}}</div>
                    <div>上课时间：{{clazz.classTime}}</div>
                    <div class="class-detail-row">
                        <div class="class-detail-row-title">任课老师：{{clazz.teacherId}}</div>
                        <!-- <div class="class-detail-row-title">学制：{{ cust.loginType == LOGINTYPE_CHILD ? clazz.gradeInfo : clazz.dicNote }}</div> -->
                      <div class="class-detail-row-title" v-if="cust.loginType == LOGINTYPE_CHILD">报名年龄：{{ clazz.ageMin + '岁 - ' + clazz.ageMax + '岁' }}</div>
                      <div class="class-detail-row-title" v-else>学制：{{ clazz.dicNote }}</div>
                    </div>
                    <div class="class-detail-row">
                        <div class="class-detail-row-title">人数：<label class="txt-price">{{clazz.qty}}</label></div>
                        <div class="class-detail-row-title" v-if="clazz.fee_cust > 0" >原价：<label class="txt-line">{{clazz.fee_ori}}元</label></div>
                        <div class="class-detail-row-title">现价：<label class="txt-price">{{ clazz.fee_cust == 0 ? '免费' : clazz.fee_cust + '元'}}</label></div>
                    </div>
                </div>
            </div>
        </van-list>
        <div class="bottom-frame">
            <van-button square block type="primary" @click="toBaodu" :loading="submitting">下一步</van-button>
        </div>
    </div>
</template>
<script>
    import {Button, Checkbox, DropdownMenu, DropdownItem, Cell, List, NoticeBar, Field} from "vant";
    import EduNavBar from "@/components/EduNavBar";
    import Tools from "@/api/Tools";
    import BaomingApi from "@/api/BaomingApi";
    import EduConstants from "@/api/EduConstants";
    import CustomerApi from "@/api/CustomerApi";

    export default {
        name: 'xzkc',
        components: {
            EduNavBar,
            VanButton: Button,
            VanCheckbox: Checkbox,
            VanDropdownMenu: DropdownMenu,
            VanDropdownItem: DropdownItem,
            VanCell: Cell,
            VanList: List,
            VanNoticeBar: NoticeBar,
            VanField: Field
        },
        data() {
            return {
                cust: Tools.getCurCust(),
                LOGINTYPE_CHILD: EduConstants.LOGINTYPE_CHILD,
                show: false,
                loading: false,
                finished: false,
                tablePage: {page: 1, pageSize: 10, condition: {className: null, showNotFull: false}},
                classes: [],
                isRetire: false, //是否已退休
                submitting: false
            }
        },
        methods: {
            checkCourse(clazz) {
                if (clazz.checked==false) {
                    /*var courses = [];
                    this.classes.forEach((value, index) => {
                        if (this.classes[index].checked) {
                            courses.push(this.classes[index])
                        }
                    })
                    courses.push(clazz)
                    if (courses.length <= 0) {
                        this.$toast({message: '请先选择要报读的课程。'})
                        return
                    }

                    var condition = { cust: this.cust, courses: courses }

                    BaomingApi.checkCourseForCheckItem(condition).then(response => {
                        if (response.res == 1){
                            clazz.checked = true
                        }
                    }).catch(() => { clazz.checked = false })*/
                    clazz.checked = true
                } else {
                    clazz.checked = false
                }

            },
            toBaodu() {
                if (this.submitting) {
                  return
                }
                var courses = [];
                this.classes.forEach((value, index) => {
                    if (this.classes[index].checked) {
                        courses.push(this.classes[index])
                    }
                })
                if (courses.length <= 0) {
                    this.$toast({message: '请先选择要报读的课程。'})
                    return
                }

                // var tips = '注意：课程报名缴费成功后72小时内可在报名系统自行办理退班（每人仅限一次，老学员报读本班和开学后报班的恕不办理退换），逾期不再办理。'
                var tips = '注意：课程报名缴费成功后，有关退、换班的规定详情请参阅《招生简章》。'
                if (this.cust.loginType == this.LOGINTYPE_CHILD) {
                    tips = '注意：课程报名缴费成功后，有关退、换班的规定详情请参阅《招生简章》。'
                }
                this.submitting = true
                this.$dialog.confirm({title: '是否确认报名选择的班级？', message: tips}).then(() => {
                    this.cust.termTime = this.$route.query.termTime
                    var condition = { cust: this.cust, courses: courses }
                    BaomingApi.submitBaodu(condition).then(response=>{
                        if (response.res == 1) {
                            this.submitting = false
                            this.$router.push({name: 'qrkc'})
                        } else {
                          this.submitting = false
                        }
                    }).catch(() => { this.submitting = false })
                }).catch(() => { this.submitting = false })
            },
            onSearch() {
                // console.log(this.tablePage.condition.className)
                this.classes = []
                this.tablePage.page = 1
                this.tablePage.condition.isShowNotFull = this.tablePage.condition.showNotFull ? '1' : null
                // console.log(this.tablePage.condition.isShowNotFull)
                this.getBaoduCourses()
                this.$refs.item.toggle();
            },
            checkIsRetire() {
                // TODO 检查是否已经退休
                CustomerApi.checkIsRetire({ loginType: this.cust.loginType, custId: this.cust.custId, term: this.$route.query.term, termTime: this.$route.query.termTime }).then(response => {
                    this.isRetire = response.res == 1
                })
            },
            getBaoduCourses() {
                // TODO 获取报读课程
                this.tablePage.condition.depId = this.$route.query.depId == undefined ? null : this.$route.query.depId
                this.tablePage.condition.term = this.$route.query.term
                this.tablePage.condition.termTime = this.$route.query.termTime
                this.tablePage.condition.loginType = this.cust.loginType
                this.tablePage.condition.custId = this.cust.custId
                this.tablePage.condition.regRule = this.cust.regRule
                this.loading = true
                BaomingApi.getBaoduCourses(this.tablePage).then(response => {
                    this.loading = false
                    for (var i = 0; i < response.res.list.length; i++) {
                        response.res.list[i].checked = false
                        this.classes.push(response.res.list[i])
                    }
                    if (response.res.isLastPage==true) {
                        this.finished = true
                    } else {
                        this.tablePage.page = response.res.pageNum + 1
                    }
                }).catch(()=>{
                    this.loading = false
                    this.finished = true
                })
            }
        },
        mounted() {
            this.checkIsRetire()
        }
    }
</script>
<style scoped>
    .txt-line {
        text-decoration: line-through;
    }
</style>
